<template>
  <b-container>
    <b-row align-h="center">
      <b-col
        lg="6"
        md="9"
        sm="12"
        class="mb-3"
      >
        <b-card
          title="Hesap Oluştur"
          :title-position="'bottom'"
        >
          <validation-observer ref="signupValidation">
            <b-form
              @submit.prevent
            >
              <b-row>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    :rules="{ required: true }"
                  >
                    <b-form-group label="Ad (*)">
                      <b-form-input
                        v-model="userObject.firstName"
                        :disabled="isLoading"
                        :state="errors.length > 0 ? false: null"
                        placeholder="Ad"
                      />
                      <b-form-invalid-feedback
                        class="text-danger"
                      >
                        Ad alanı boş olamaz.
                      </b-form-invalid-feedback>
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    :rules="{ required: true }"
                  >
                    <b-form-group label="Soyad (*)">
                      <b-form-input
                        v-model="userObject.lastName"
                        :disabled="isLoading"
                        :state="errors.length > 0 ? false: null"
                        placeholder="Soyad"
                      />
                      <b-form-invalid-feedback
                        class="text-danger"
                      >
                        Soyad alanı boş olamaz.
                      </b-form-invalid-feedback>
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="E-Mail"
                    :rules="{ required: true, email: false }"
                  >
                    <b-form-group label="E-Posta adresi (*)">
                      <b-form-input
                        v-model="userObject.email"
                        :disabled="isLoading"
                        :state="errors.length > 0 ? false: null"
                        type="email"
                        placeholder="E-Posta adresi"
                      />
                      <b-form-invalid-feedback
                        class="text-danger"
                      >
                        E-Posta alanı boş olamaz.
                      </b-form-invalid-feedback>
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Phone Number"
                    :rules="{ required: true, integer: true }"
                  >
                    <b-form-group label="Telefon (*)">
                      <b-form-input
                        v-model="userObject.gsm"
                        :disabled="isLoading"
                        :state="errors.length > 0 ? false: null"
                        type="tel"
                        placeholder="Telefon"
                      />
                      <b-form-invalid-feedback
                        class="text-danger"
                      >
                        Telefon alanı boş olamaz.
                      </b-form-invalid-feedback>
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    :rules="{ required: true }"
                  >
                    <b-form-group label="Şifre (*)">
                      <b-form-input
                        v-model="userObject.password"
                        :disabled="isLoading"
                        :state="errors.length > 0 ? false: null"
                        :type="passwordFieldType"
                        placeholder="Şifre"
                        class="form-control-merge"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                      <b-form-invalid-feedback
                        class="text-danger"
                      >
                        Şifre alanı boş olamaz.
                      </b-form-invalid-feedback>
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    name="ConfirmPassword"
                    :rules="{required: true, is: userObject.password}"
                  >
                    <b-form-group label="Şifre (tekrar)">
                      <b-form-input
                        v-model="confirmPassword"
                        :disabled="isLoading"
                        :state="errors.length > 0 ? false: null"
                        :type="passwordRetypeFieldType"
                        placeholder="Şifre"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordRetypeToggleIcon"
                          @click="togglePasswordRetypeVisibility"
                        />
                      </b-input-group-append>
                      <b-form-invalid-feedback
                        class="text-danger"
                      >
                        Şifre alanı aynı olmalıdır.
                      </b-form-invalid-feedback>
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  class="text-right"
                >
                  <b-form-group class="mt-3">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      :disabled="isLoading"
                      @click="btnSignUpClicked"
                    >
                      <div v-show="!isLoading">
                        Kayıt Ol
                      </div>
                      <div v-show="isLoading">
                        Kayıt tamamlandı!
                      </div>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <b-modal
            id="signup-modal"
            cancel-variant="outline-warning"
            ok-title="İleri"
            cancel-title="Daha sonra hatırlat"
            centered
            :hide-header-close="true"
            no-close-on-backdrop
            title="Kayıt Ol"
            @ok="onModalSignUpOk"
            @cancel="onModalSignUpCancelled"
          >
            <sign-up-landing @signUpTypeChanged="onSignUpTypeChanged" />
          </b-modal>
        </b-card>
        <b-modal
          id="modal-success"
          ok-only
          ok-variant="success"
          ok-title="Accept"
          modal-class="modal-success"
          centered
          title="Success Modal"
        >
          <b-card-text>
            Sayın {{ newapplicant.applicantName }}, <br><br>
            Üyelik kaydınız tamamlanmıştır. Hesap doğrulama linki {{ newapplicant.applicantEmail }} adresine gönderilmiştir. <br><br>
            E-Posta adresinizi doğruladıktan sonra giriş yapabilirsiniz. <br><br><br><br>
            Kariyer NE10 Balıkesir
          </b-card-text>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>

</template>
<script>
import { mapActions } from 'vuex'
import { signup, assignUserGroup } from '@/api/auth'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, integer, is,
} from '@validations'
import {
  BContainer, BRow, BCol, BForm, BFormGroup, BCard, BFormInput, BButton, BInputGroupAppend, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SignUpLanding, { SignUpType } from '@/views/usersignup/SignUpLanding.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroupAppend,
    BCard,
    BFormInput,
    BButton,
    SignUpLanding,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      signUpType: '',
      userObject: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        gsm: '',
      },
      userGroup: {},
      confirmPassword: '',
      isLoading: false,
      // import validation rules
      required,
      email,
      integer,
      is,
      authUserId: 0,
      // Applicant Defaults
      newapplicant: {
        applicantId: 0,
        applicantName: '',
        applicantTCKN: '',
        applicantEmail: '',
        applicantPhone: '',
        city: '',
        town: '',
        district: '',
        street: '',
        doorNumber: '',
        addressDefinition: '',
        birthDate: '1900-01-01T00:00:00.000Z',
        birthCity: '',
        birthTown: '',
        gender: 0,
        maritalStatus: 0,
        militaryStatus: 0,
        endDateDelay: '1900-01-01T00:00:00.000Z',
        heightCm: 0,
        weightKg: 0,
        isDisabled: true,
        isSmoker: true,
        canTravel: true,
        hasWorkShifts: true,
        hasReferences: true,
        hasCriminalRecord: true,
        canDrive: true,
        driverLicenseTypeId: 0,
        driverLicenseType: {
          driverLicenceTypeId: 0,
          driverLicenceTypeName: '',
          rowState: 0,
        },
        obstacleDiseases: '',
        photo: '',
        rowState: 1,
        applicantCompleted: 0,
        authUserId: 0,
        registrationDate: '2021-12-29T13:37:03.434Z',
        updatedDate: '2021-12-29T13:37:03.434Z',
        validationErrors: '',
        status: 1,
      },
      newFirm: {
        firmInfoId: 0,
        firmTitle: '',
        firmType: '',
        firmSectors: [],
        firmActivityArea: '',
        firmWebSite: '',
        firmTelephone1: '',
        firmTelephone2: '',
        firmEmail: '',
        firmCustomerGroupDomestic: 0,
        firmCustomerGroupAbroad: 0,
        firmCountOfStaffWhiteCollar: 0,
        firmCountOfStaffBlueCollar: 0,
        firmCountOfBranchInProvince: 0,
        firmCountOfTotalBranch: 0,
        firmTransportationService: '',
        firmCateringService: '',
        firmWeekdayMorningWorkingHoursStart: '',
        firmWeekdayMorningWorkingHoursEnd: '',
        firmWeekdayLunchBreakStart: '',
        firmWeekdayLunchBreakEnd: '',
        firmWeekdayOvertimeStart: '',
        firmWeekdayOvertimeEnd: '',
        firmWeekdayOvertimeBreakStart: '',
        firmWeekDayOvertimeBreakEnd: '',
        firmWeekendMorningWorkingHoursStart: '',
        firmWeekendMorningWorkingHoursEnd: '',
        firmWeekendLunchBreakStart: '',
        firmWeekendLunchBreakEnd: '',
        firmWeekendOvertimeStart: '',
        firmWeekendOvertimeEnd: '',
        firmWeekendOvertimeBreakStart: '',
        firmWeekendOvertimeBreakEnd: '',
        firmLogo: '',
        rowState: 1,
        authUserId: 0,
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordRetypeToggleIcon() {
      return this.passwordRetypeFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions({
      signIn: 'auth/action_login',
    }),
    onModalSignUpOk() {
      if (this.signUpType === SignUpType.Applicant) {
        this.userGroup = {
          username: this.userObject.username,
          password: this.userObject.password,
          groupId: 4,
        }
        assignUserGroup(this.userGroup).then(() => {
          const h = this.$createElement
          const messageVNode = h('div', [
            h('p', { class: ['text-center font-weight-bold text-danger'] }, [` Sayın,  ${this.userObject.firstName} ${this.userObject.lastName} hoşgeldiniz.`]),
            h('p', [' Üyelik bilgileriniz kayıt edilmiştir.']),
            h('p', [' E-posta adresi doğrulama işleminizden sonra giriş yapabilir CV nizi oluşturabilirsiniz.']),
          ])
          this.$bvModal
            .msgBoxOk(messageVNode, {
              title: 'Aday Kayıt Onayı',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Tamam',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if (value) {
                this.$router.push({ name: 'home' })
              }
            })
        })
      } else if (this.signUpType === SignUpType.Employer) {
        this.userGroup = {
          username: this.userObject.username,
          password: this.userObject.password,
          groupId: 3,
        }
        assignUserGroup(this.userGroup).then(() => {
          const h = this.$createElement
          const messageVNode = h('div', [
            h('p', { class: ['text-center font-weight-bold text-danger'] }, [` Sayın,  ${this.userObject.firstName} ${this.userObject.lastName} hoşgeldiniz.`]),
            h('p', [' Üyelik bilgileriniz kayıt edilmiştir.']),
            h('p', [' E-posta adresi doğrulama işleminizden sonra giriş yapabilir Firma bilgilerinizi oluşturabilirsiniz.']),
          ])
          this.$bvModal
            .msgBoxOk(messageVNode, {
              title: 'Firma Kayıt Onayı',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Tamam',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if (value) {
                this.$router.push({ name: 'home' })
              }
            })
        })
      }
    },
    onModalSignUpCancelled() {
      this.$router.push({ name: 'login' })
    },
    onSignUpTypeChanged(type) {
      this.signUpType = type
    },
    btnSignUpClicked() {
      this.$refs.signupValidation.validate().then(success => {
        if (success) {
          this.userObject.username = this.userObject.email
          signup(this.userObject).then(res => {
            if (res.data.entity > 0) {
              this.authUserId = res.data.entity
              this.isLoading = true
              this.$swal({
                title: 'Başarılı',
                text: 'Hesabınız oluşturuldu!',
                icon: 'success',
                showConfirmButton: false,
                timer: 3000,
              }).then(async () => {
                await new Promise(resolve => setTimeout(resolve, 1000))
                this.$bvModal.show('signup-modal')
              })
            }
          })
        }
      })
    },
  },
}
</script>
<style scoped>
.swal-title{
  font-size: 14px !important;
}
.swal-text{
  font-size: 12px !important;
}
</style>
